import React from 'react';
import FadeInImage from './FadeInImage';

const doctorsData = [
  {
    name: "Cameron MacMillan, CEO",
    bio: `Cameron joined the luxury travel industry in 2014 after three successful years as a copy-editor in the medical industry and following a degree in Journalism from Fordham University. The son of an interior designer mother and a developer father, Cameron grew up traveling and idolized hotels with top service and impeccably designed interiors. 

Leveraging his experience with some of the world's top properties and his attention to detail, he quickly climbed the travel industry ranks to management level where he grew various firms to unprecedented new levels. Deemed an expert of taking boutique luxury travel agencies and turning them into midsize and larger firms, he implemented membership structures, referral programs, and marketing campaigns that aided in the company’s growth. 

Founding a new concept where celebrities and tastemakers led intimate-group trips to destinations that enhanced their industry, he was featured on the cover of The Wall Street Journal as a prominent figure in the luxury travel industry and the “impresario” who created these luxury trips.  

Cameron co-founded Sinclair Retreats with two friends and business partners after years of ideation between the three. The perfect place and time philosophy was in full bloom as they strive to make medical travel more available to the masses.
`,
    photo: "./assets/images/founders/ceo.webp",
  },
  {
    name: "Patrick Manasse, COO",
    bio: `Patrick was born and raised on the French Riviera. Having lived in nearly every corner of the globe, he now calls New York City home. Patrick’s legal career began while assisting on a case in 2008 when he caught the eye of Debra Guzov, a named partner at a New York City based firm who offered him a position at Guzov Ofsink. After passing the Bar, Patrick joined the firm as an associate working for both the Corporate and Commercial Litigation groups. He appeared in a variety of the New York City courts and engaged in international multi-party litigation in a variety of complex commercial matters. During his time at the firm he also negotiated private placements, cross-border transactions, and stock listing processes.
 
Through his exposure to a wide variety of businesses, and with his global view of the world, Patrick joined a number of organizations as Board Member or Advisor. In particular, Patrick serves on the board of Clubhouse International, a non-profit organization focused on mental health which has provided accreditation to 350 facilities in communities across 32 countries to help find cost-effective and sustainable solutions for mental illness.
 
Patrick currently serves as Chief Compliance Officer at a technology company which provides financial networks real time fraud prevention solutions. Patrick is a registered attorney in New York with a Bachelor Degree in Economics, a Master's in Business Administration, and a Juris Doctor. Patrick co-founded Sinclair Retreats with business partners to facilitate medical travel for those seeking top tier care at reasonable prices.
`,
    photo: "./assets/images/founders/coo.webp",
  },
  {
    name: "Robert Torgov, CTO",
    bio: `Robert was born and raised in New York City, immersed in the vibrant energy of a city that constantly pushes boundaries. From an early age, he developed a deep fascination with modern engineering and technology, quickly channeling this passion into programming. By high school, Robert was already building online communities, reverse engineering video games, and mastering various programming languages through self-study.
He earned his Bachelor of Science degree while contributing to the growth of a financial technology startup in New York. During his time there, Robert played a pivotal role in launching the firm's API gateway for its secure financing product, an experience that honed his ability to create robust, production-grade software under strict regulatory standards. He later took on a senior role at a prominent Digital Asset Custodian company, leading initiatives to optimize and redesign key systems for billing, reporting, policy, and user management.
With over a decade of experience in developing high-performance financial software, Robert is now pursuing a noble mission to address the growing challenges in healthcare. In a world where medical debt is skyrocketing while the quality of care declines, he is focused on making high-quality medical travel more affordable and easier to navigate. As a co-founder of Sinclair Retreats, Robert is leading the development of a platform aimed at revolutionizing medical tourism by creating a more connected and streamlined experience for patients seeking care abroad.
`,
    photo: "./assets/images/founders/cto.webp",
  }
];

function FounderGrid() {
  return (
    <>
    <div id="hero" className="doctor-hero" style={{ backgroundColor: '#f8f9fa' }}></div>
    <div style={{ height: '25px', backgroundColor: '#f8f9fa' }}></div>
    <div className="container py-5">
        <section key={1} className="mb-5">
          <h2 className="text-center mb-4 section-title h2">{"Our Team"}</h2>
        {doctorsData.map((doctor, index) => (
          <div className="row">
            {
              <div
                key={doctor.name}
                className={`col-md-12 d-flex align-items-center flex-${index % 2 === 0 ? 'row' : 'row-reverse'} doctor-row`}
              >
                <div className="doctor-photo-wrapper me-3">
                  <FadeInImage
                    src={doctor.photo}
                    alt={doctor.name}
                    className="img-fluid rounded-circle doctor-photo fade-in-img"
                  />
                </div>
                <div className="doctor-info">
                  <h5 className="mb-1">{doctor.name}</h5>
                  <p className="text-muted">{doctor.bio}</p>
                </div>
              </div>
            }
          </div>
           ))}
        </section>
    </div>
    </>
  );
}

export default FounderGrid;