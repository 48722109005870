import React, { useEffect } from 'react';
import TripInclusionsSection from './TripInclusions';
import FadeInImage from './FadeInImage';
import './backProgram.css';

// Updated itinerary data for the back program
const itineraryData = [
  {
    day: 1,
    date: 'Day 1',
    title: 'Day 1',
    description: `Arrive in Croatia, where you will be transferred to your hotel. Settle in for a rest before meeting your host. Enjoy a joint dinner during which you and your host will discuss the plan for the week.`,
    image: './assets/images/back_program/day_1.webp', // Replace with your actual image path
  },
  {
    day: 2,
    date: 'Day 2',
    title: 'Day 2',
    description: `Enjoy breakfast at the hotel. The day will begin with a morning lecture, followed by a city hike that offers a natural way to settle in and get some exercise after your flight. Savor tea before your stretching program. Relax during lunch before an afternoon thermotherapy session and lecture. Embark on a nighttime city hike before dinner.`,
    image: './assets/images/back_program/day_2.webp',
  },
  {
    day: 3,
    date: 'Day 3',
    title: 'Day 3',
    description: `Enjoy breakfast at the hotel. Start with a lecture, then take a city hike that concludes with tea and light snacks. Return to the hotel for a rest before lunch and an afternoon lecture focused on your core. Immediately afterward, a core training session will put your new knowledge into practice. Enjoy a sauna and massage before heading to dinner to end the day.`,
    image: './assets/images/back_program/day_3.webp',
  },
  {
    day: 4,
    date: 'Day 4',
    title: 'Day 4',
    description: `Enjoy breakfast at the hotel. Today’s morning lecture will further explore core training and build upon your previous lessons. Enjoy another city walk and tea before heading to lunch. After a brief rest, indulge in a sauna session followed by an intensive stretch paired with a massage to conclude the afternoon. Finish with dinner at the hotel.`,
    image: './assets/images/back_program/day_4.webp',
  },
  {
    day: 5,
    date: 'Day 5',
    title: 'Day 5',
    description: `Enjoy breakfast at the hotel. Today, you will have a private transfer to Bliznec, a stunning forest trail just outside Zagreb. Spend the day on an intense hike, with lunch and coffee at the mountain summit. Afterwards, you will be transferred back to your hotel for a core and stretching session, followed by tea and some free time. Finish with dinner at your hotel.`,
    image: './assets/images/back_program/day_5.webp',
  },
  {
    day: 6,
    date: 'Day 6',
    title: 'Day 6',
    description: `Enjoy breakfast at your hotel. Next, you’ll have a morning lecture followed by a city walk where you can put into practice what you learned earlier. Stop for tea before returning to your hotel for lunch. After lunch, enjoy some free time to relax with a sauna, stretching, and a massage before a final core session. Finish with dinner at your hotel.`,
    image: './assets/images/back_program/day_6.webp',
  },
  {
    day: 7,
    date: 'Day 7',
    title: 'Day 7',
    description: `Enjoy breakfast at your hotel. Begin the morning with a massage before embarking on a city walk and tea, then return to the hotel for lunch. After lunch and a brief stretch, enjoy an afternoon at your leisure. Finish with dinner at your hotel before setting out for your final night out in Croatia—a reward for completing the week-long program.`,
    image: './assets/images/back_program/day_7.webp',
  },
  {
    day: 8,
    date: 'Day 8',
    title: 'Day 8',
    description: `Enjoy breakfast at the hotel. You will then have a private transfer to the airport for your flight home. Safe travels!`,
    image: './assets/images/back_program/day_8.webp',
  },
];

const ItineraryDay = React.memo(({ day, date, description, image, index }) => {
  const isOdd = index % 2 !== 0;

  return (
    <div className="row align-items-center itinerary-day">
      {!isOdd ? (
        <>
          {/* Image on the left */}
          <div className="col-md-6 itinerary-img-container">
            <FadeInImage 
              src={image} 
              alt={`Day ${day}`} 
              className="fade-in-img img-fluid" 
            />
          </div>
          {/* Text on the right */}
          <div className="col-md-6 itinerary-text-container">
            <div className="w-75 mx-auto text-start">
              <h5>{date}</h5>
              <p>{description}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* Text on the left */}
          <div className="col-md-6 itinerary-text-container">
            <div className="w-75 mx-auto text-start">
              <h5>{date}</h5>
              <p>{description}</p>
            </div>
          </div>
          {/* Image on the right */}
          <div className="col-md-6 itinerary-img-container">
            <FadeInImage 
              src={image} 
              alt={`Day ${day}`} 
              className="fade-in-img img-fluid" 
            />
          </div>
        </>
      )}
    </div>
  );
});

function BackProgram() {

  const scrollToTop = () => {
    const hash = window.location.hash;
    if (!hash) {
      // Scroll to the element with the corresponding ID
      const element = document.getElementById('hero');
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll
        }, 200);
      }
    }
  };

  useEffect(() => {
    scrollToTop()
  }, []);
  
  return (
    <div>
      {/* HERO SECTION */}
      <section className="back-program-hero" id="hero">
        <div className="back-program-hero-content container">
          <h1 className="display-4 mb-3">The Pervan Method</h1>
        </div>
      </section>

      {/* Information Strip */}
      <section className="info-strip bg-dark text-white">
        <div className="container py-3">
          <div className="row text-center text-md-start">
            {/* Optional: text-center on smaller screens, text-md-start for larger screens */}

            {/* First item */}
            <div className="col-6 col-md-4 mb-3 mb-md-0 d-flex justify-content-center">
              {/* Inner wrapper for left-aligned text */}
              <div>
                <small className="d-block">Length</small>
                <div className="fs-6 fw-bold">8 Days, 7 Nights</div>
              </div>
            </div>

            {/* Second item */}
            <div className="col-6 col-md-4 mb-3 mb-md-0 d-flex justify-content-center">
              <div>
                <small className="d-block">Focus</small>
                <div className="fs-6 fw-bold">Lower Back Pain</div>
              </div>
            </div>

            {/* Third item */}
            <div className="col-12 col-md-4 d-flex justify-content-center">
              <div>
                <small className="d-block">Priced from (Per Person)</small>
                <div className="fs-6 fw-bold">$7,475</div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* Summary of proram */}
      <section className="py-5">
        <div className="container">
          <div className="row align-items-center">
            {/* Left Column: Image */}
            <div className="col-md-6 col-sm-12 mb-4 mb-md-0 d-flex justify-content-center">
              <img
                src="./assets/images/back_program/massage.jpg"
                alt="Back Expert"
                className="img-fluid rounded"
              />
            </div>

            {/* Right Column: Summary Text */}
            <div className="col-md-6">
              <p>
                Spend a week with one of the world's most renowned back experts. This
                all-inclusive package contains activation elements focusing on lower back
                pain prevention and alleviation. It consists of practical lectures
                incorporated into a health, fitness, diet, and spa routine to alleviate
                discomfort and pain in the lower back region. Designed by Vazmenko Pervan
                himself, the program blends active city hikes and walks with guided
                kinesitherapy, training sessions, and therapeutic treatments—such as
                thermo, physio, pool, and spa sessions—while also incorporating planned
                nutrition, rest, and relaxation to provide optimal relief for clients with
                acute back pain.
              </p>
              <p>
                Your base for the week will be the centrally located Esplanade Hotel in
                Zagreb—a five-star property that provides easy access to the entire city
                for your guided walks and hikes. As you explore, you'll benefit from
                Zagreb's unique vertical layout connecting its historic and modern
                districts, and you'll also have the opportunity to seek out specialty
                coffee, premium teas, and spring water in line with your meal plan.
              </p>
              <p>
                Before you return home, you'll receive a fully tailored plan for your
                ongoing treatment, and the doctor will be available to answer any questions
                you may have.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* MEET YOUR HOST SECTION */}
      <section className="py-5 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 d-flex flex-column justify-content-center">
              <h2 className="mb-3">Meet Your Host</h2>
              <h3>Vazmenko Pervan</h3>
              <p>
                Vazmenko Pervan is a wellness innovator with a talent for transforming lives through personalized fitness and rehabilitation. As a Professor of Kinesiology, program creator and therapist at the Kempinski Hotel in Istria, and the founder and master instructor at Reforma Fitness Center for over 20 years, he specializes in designing tailored programs to address spinal pain, posture issues, and metabolic imbalances. By blending scientific and historical insights with his personal experience recovering from spine injuries, he provides empathetic and effective guidance.
              </p>
            </div>
            <div className="col-md-6 col-sm-12 mb-4 mb-md-0 d-flex justify-content-center pervan-img-container">
              <img
                src="./assets/images/pervan.webp"
                alt="Vazmenko Pervan"
                className="img-fluid rounded itinerary-img"
              />
            </div>
          </div>
        </div>
      </section>

      {/* ITINERARY SECTION */}
      <section className="py-5">
        <div className="container">
          <h2 className="mb-4">Itinerary</h2>
          {itineraryData.map((dayObj, index) => (
            <ItineraryDay key={dayObj.day} index={index} {...dayObj} />
          ))}
        </div>
      </section>

      {/* TRIP INCLUSIONS SECTION */}
      <TripInclusionsSection />
    </div>
  );
}

export default BackProgram;