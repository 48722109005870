import React, { useState, useRef, useEffect } from 'react';

const FadeInImage = ({ src, alt, className = '', style = {}, ...props }) => {
  const [loaded, setLoaded] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    // If the image is already cached and complete, mark it as loaded
    if (imgRef.current && imgRef.current.complete) {
      setLoaded(true);
    }
  }, [src]);

  const handleLoad = () => {
    setLoaded(true);
  };

  return (
    <img
      ref={imgRef}
      src={src}
      alt={alt}
      onLoad={handleLoad}
      // Append a "loaded" class when the image has finished loading
      className={`${className} ${loaded ? 'loaded' : ''}`}
      style={style}
      {...props}
    />
  );
};

export default React.memo(FadeInImage);