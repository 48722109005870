import React, { useState, useEffect } from 'react';

function CTA() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    selectedPackage: 'dental', // Default selection for the dropdown
    partner: '', // New field for the partner query param
  });

  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // On mount, check for a partner query parameter and update state if found.
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const partner = params.get('partner');
    if (partner) {
      setFormData((prev) => ({ ...prev, partner }));
    }
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    setStatus(null); // Clear any previous status messages
    try {
      const response = await fetch('/api/send', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setShowModal(true);
        setFormData({
          name: '',
          email: '',
          selectedPackage: 'dental',
          partner: formData.partner, // Keep partner value for tracking
        });
        window.gtag_report_conversion();
      } else {
        setStatus('Failed to submit your inquiry. Please contact us at inquiry@sinclairretreats.com');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('An unexpected error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="contact-form-section py-5" id="cta">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <h2 className="form-heading mb-4">Travel with us!</h2>
            <p className="lead">
              Please take the time to fill out the form and a member of our team will reply shortly to start the journey to a better you.
            </p>
          </div>
          <div className="col-lg-6">
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-3">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Enter your full name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control"
                  placeholder="Enter your email address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="selectedPackage">Package</label>
                <select
                  id="selectedPackage"
                  name="selectedPackage"
                  className="form-control"
                  value={formData.selectedPackage}
                  onChange={handleChange}
                  required
                >
                  <option value="dental">Dental</option>
                  <option value="cosmetic">Cosmetic</option>
                  <option value="hair">Hair</option>
                  <option value="custom">Custom</option>
                </select>
              </div>
              {/* Hidden input for the partner query param */}
              <input type="hidden" name="partner" value={formData.partner} />
              <button
                type="submit"
                className="btn btn-primary learn-more-btn w-100"
                disabled={loading}
              >
                {loading ? (
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                ) : (
                  'Submit'
                )}
              </button>
            </form>
            {status && <p>{status}</p>}
          </div>
        </div>
        {/* Success Modal */}
        {showModal && (
          <div
            className="modal show d-block"
            tabIndex="-1"
            role="dialog"
            style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body row align-items-center">
                  <div className="col-lg-12">
                    <h3 className="modal-title mb-3">Thank you!</h3>
                    <p>Your inquiry has been submitted successfully!</p>
                    <p>Please keep an eye out for a follow up email from our team.</p>
                  </div>
                  <div className="col-lg-12">
                    <button
                      type="button"
                      className="btn btn-primary learn-more-btn w-100"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default CTA;
