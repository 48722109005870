import React from 'react';
import { Link } from 'react-router-dom';

function DoctorGalleryItem(key, url) {
  return (
    <li className="gallery-item" key={key}>
      <figure className="gallery-image">
        <Link to={`/ourdoctors#hero`}>
          <img src={url} alt="DoctorGallery"/>
        </Link>
      </figure>
    </li>
  );
}

function DoctorGallery() {
  return (
    <section className="gallery" id="gallery">
      <div className="container text-center">
        <h2 className="h2 section-title mb-4">Meet our doctors</h2>
        <ul className="gallery-list">
          {[
            DoctorGalleryItem(0, "./assets/images/doctors/doctor_1.webp"),
            DoctorGalleryItem(1, "./assets/images/doctors/doctor_2.webp"),
            DoctorGalleryItem(2, "./assets/images/doctors/doctor_5.webp"),
            DoctorGalleryItem(3, "./assets/images/doctors/toncic_2.webp"),
            DoctorGalleryItem(4, "./assets/images/doctors/doctor_3.webp"),
          ]}
        </ul>

        {/* Centered "Learn More" Button */}
        <Link to="/ourdoctors#hero" className="btn btn-primary learn-more-btn">
          Learn More
        </Link>
      </div>
    </section>
  );
}

export default DoctorGallery;
