import React from 'react';
import { Link } from 'react-router-dom';

function PervanMethodSection() {
  return (
    <section className="pervan-method-section" id="pervan-method">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12">
            <div className="mx-auto">
              <h2 className="mb-3 h2">The Pervan Method</h2>
              <h4 className="mb-3">A Week-long back-pain management program</h4>
            </div>
            <div className="mx-auto">
              <p className="pervan-text">
                {`
                Discover our signature back-pain program designed by Vazmenko Pervan, 
                a renowned expert in spinal health. This comprehensive approach blends 
                active city hikes, guided kinesitherapy, specialized training sessions, 
                and spa treatments to alleviate lower back pain. Learn how you can 
                achieve relief and renewed vitality through personalized care and 
                holistic wellness.
              `}
              </p>
            </div>
            <div className="mx-auto text-center mb-5">
              {/* Call-to-Action Button */}
              <Link to="/backprogram" className="btn btn-primary learn-more-btn">
                View the itinerary
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <img
              style={{ width: '90%', height: '90%' }}
              src={"./assets/images/pervan_front_page.webp"}
              alt="Client enjoying one of the daily spa treatments offered by the Pervan Method"
              className="img-fluid mx-auto"
            />
          </div>
        </div>
      </div >
    </section >
  );
}

export default PervanMethodSection;