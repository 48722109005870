import React from 'react';
// Example: using Bootstrap icons. Make sure you've imported the Bootstrap Icons CSS
// e.g., `npm install bootstrap-icons --save` and `import 'bootstrap-icons/font/bootstrap-icons.css';`
import 'bootstrap-icons/font/bootstrap-icons.css';
import './tripInclusions.css';

function TripInclusionsSection() {
  return (
    <section className="trip-inclusions-section">
      <div className="container py-5">

        {/* Outer row: heading on the left, list on the right */}
        <div className="row">
          {/* Column for Title */}
          <div className="col-12 col-md-3">
            <h2 className="title-row">Trip Inclusions</h2>
          </div>

          {/* Column for the UL (with nested row) */}
          <div className="col-12 col-md-9">
            <ul className="list-unstyled">
              {/* Inner row for 2×2 layout on md+ screens */}
              <div className="row">
                {/* Each LI is a column in this nested row */}
                <li className="col-12 col-md-6 d-flex mb-3">
                  <i className="bi bi-house-fill inclusion-icon me-2"></i>
                  <div className="d-block">
                    <strong>Accommodation</strong>
                    <span>
                      7 nights at the Esplanade Hotel in Zagreb
                    </span>
                  </div>
                </li>

                <li className="col-12 col-md-6 d-flex mb-3">
                  <i className="bi bi-truck inclusion-icon me-2"></i>
                  <div className="d-block">
                    <strong>Transportation</strong>
                    <span>
                      Private transfers to/from the airport on your arrival and departure days. Private transfers to all activities as noted in the itinerary.
                    </span>
                  </div>
                </li>

                <li className="col-12 col-md-6 d-flex mb-3">
                  <i className="bi bi-globe2 inclusion-icon me-2"></i>
                  <div className="d-block">
                    <strong>Tours</strong>
                    <span>
                      All city walks and tours, spa sessions, lectures, and therapy sessions as noted in the itinerary.
                    </span>
                  </div>
                </li>

                <li className="col-12 col-md-6 d-flex mb-3">
                  <i className="bi bi-egg-fried inclusion-icon me-2"></i>
                  <div className="d-block">
                    <strong>Meals</strong>
                    <span>
                      All meals noted in the itinerary (3 meals per day plus tea and snacks)
                    </span>
                  </div>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TripInclusionsSection;
