import React, { useEffect } from 'react';
import ScrollLink from './ScrollLink';

function Header({ sticky }) {
  useEffect(() => {
    const overlay = document.querySelector("[data-overlay]");
    const navOpenBtn = document.querySelector("[data-nav-open-btn]");
    const navbar = document.querySelector("[data-navbar]");
    const navCloseBtn = document.querySelector("[data-nav-close-btn]");
    const navLinks = document.querySelectorAll("[data-nav-link]");
    const navElmCloseArr = [navCloseBtn, overlay];
    const navElmOpenArr = [navOpenBtn];


    const navOpen = function () {
      navbar.classList.add("active");
      overlay.classList.add("active");
    };

    const navClose = function () {
      navbar.classList.remove("active");
      overlay.classList.remove("active");
    };

    const navEvent = function (elem, fn) {
      for (let i = 0; i < elem.length; i++) {
        elem[i].addEventListener("click", fn);
      }
    }

    navEvent(navElmOpenArr, navOpen);
    navEvent(navElmCloseArr, navClose);
    navEvent(navLinks, navClose);

    const header = document.querySelector("[data-header]");
    const goTopBtn = document.querySelector("[data-go-top]");

    const onScroll = () => {
      if (window.scrollY >= 175) {
        header.classList.add("active");
        goTopBtn.classList.add("active");
      } else if (!sticky) {
        header.classList.remove("active");
        goTopBtn.classList.remove("active");
      }
    };

    window.addEventListener("scroll", onScroll);

    // Clean up event listeners on unmount
    return () => {
      navElmOpenArr.forEach(el => {
        el.removeEventListener("click", navOpen)
      });
      navElmCloseArr.forEach(el => {
        el.removeEventListener("click", navClose)
      });
      navLinks.forEach(link => {
        link.removeEventListener("click", navClose)
      });
      window.removeEventListener("scroll", onScroll);
    };
  }, [sticky]);

  return (
    <header className={`header ${sticky ? 'active' : ''}`} data-header>
      <div className="overlay" data-overlay></div>
      <div className={`header-top ${sticky ? 'active' : ''}`}>
        <div className="container">
          <div className="helpline-box">
            {/* Company phone was here, removed but kept to ensure padding */}
          </div>
          <ScrollLink to={'/#home'} className="logo">
            <img src="./assets/images/logo.svg" alt="Sinclair Retreats logo" />
          </ScrollLink>
          <div className="header-btn-group">
            <button className="nav-open-btn" aria-label="Open Menu" data-nav-open-btn>
              <ion-icon name="menu-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <nav className="navbar navbar-expand-lg bg-body-tertiary" data-navbar>
          <div className="container-fluid">
            <ScrollLink className="navbar-brand position-fixed active-logo" to={'/#home'}>
              <img src="./assets/images/logo.svg" alt="Sinclair Retreats logo" />
            </ScrollLink>
            <div className="navbar-top w-100">
              <ScrollLink className="logo" to={'/#home'}>
                <img src="./assets/images/logo.svg" alt="Sinclair Retreats logo" />
              </ScrollLink>
              <button className="nav-close-btn" aria-label="Close Menu" data-nav-close-btn>
                <ion-icon name="close-outline"></ion-icon>
              </button>
            </div>
            <div className="navbar-collapse justify-content-center" id="navbarNav">
              <ul className="navbar-nav navbar-list">
                <li className="nav-item">
                  <ScrollLink to={'/#home'} className="navbar-link" data-nav-link>Home</ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink to={'/#packages'} className="navbar-link" data-nav-link>packages</ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink to={'/#pervan-method'} className="navbar-link" data-nav-link>Pervan Method</ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink to={'/#mission'} className="navbar-link" data-nav-link>our mission</ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink to={'/#gallery'} className="navbar-link" data-nav-link>our doctors</ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink to={'/#cta'} className="navbar-link" data-nav-link>Contact Us</ScrollLink>
                </li>
                <li className="nav-item">
                  <ScrollLink to={'/faq/#hero'} className="navbar-link" data-nav-link>FAQ</ScrollLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
