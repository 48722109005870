import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col } from 'react-bootstrap';

export const faqs = [
  {
    question: "What is included in my package?",
    answer:
      "Top accommodation for the night of your procedure and accompanying rest days, breakfast each day, the treatment itself, round trip luxury airport transfers, roundtrip luxury transfers to your treatment, and a concierge with you the entire day of your treatment.",
  },
  {
    question: "What’s not included in my package?",
    answer:
      "International flights, meals not noted in the itinerary, extras purchased while traveling, and anything else not noted in the itinerary.",
  },
  {
    question: "What does the 30-minute video consultation with my doctor look like?",
    answer:
      "After you submit your inquiry, we'll set you up with the best doctor for your chosen treatment. A date and time that works for both parties will be picked, and you will be sent a list of pre-meeting items that need to be gathered.",
  },
  {
    question: "Exactly how much cheaper are doctors overseas than doctors in the United States?",
    answer:
      "You can expect to receive the same level of care or better with our doctors and pay, on average, 50 to 70 percent less depending on treatment type compared to similar treatments in the United States.",
  },
  {
    question: "Will my medical information be safe and private?",
    answer:
      "All of our practices are PCI compliant. We adhere to all government and HIPAA guidelines when it comes to your travel and medical information.",
  },
  {
    question: "What does follow-up care look like?",
    answer:
      "Every procedure is different, however we consult with each doctor and book you the appropriate number of nights in-country for your recovery to ensure you’re prepared for the flight home.",
  },
  {
    question: "Can I bring a friend?",
    answer:
      "Yes! We encourage you to travel with a friend or a group. We can easily accommodate a group wanting to travel together for their respective treatments and can offer discounts.",
  },
];

function FAQPage() {
  return (
    <>
      <div id="hero" className="doctor-hero" style={{ backgroundColor: '#f8f9fa' }}></div>
      <div style={{ height: '25px', backgroundColor: '#f8f9fa' }}></div>
        <div className="container py-5">
          <section className='faq-section'>
            <h2 className="section-title text-center mb-5">Frequently Asked Questions</h2>
            <Row className="align-items-center">
              {/* Left Column: Image */}
              <Col md={5} className="text-center">
                <img
                  src="/assets/images/faq.webp"
                  alt="FAQ illustration"
                  className="img-fluid"
                  style={{ borderRadius: '8px' }}
                  loading="lazy"
                />
              </Col>

              {/* Right Column: FAQ Accordion */}
              <Col md={7}>
                <Accordion>
                  {faqs.map((faq, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                      <Accordion.Header>{faq.question}</Accordion.Header>
                      <Accordion.Body>{faq.answer}</Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Col>
            </Row>
          </section>
        </div>
    </>
  );
}

export default FAQPage;
