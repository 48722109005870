import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './style.css';
import Header from './Header';
import Hero from './Hero';
import PopularDestinations from './PopularDestinations';
import MissionSection from './MissionSection';
import DoctorGallery from './DoctorGallery';
import CTA from './CTA';
import Footer from './Footer';
import BespokeSection from './BespokeSection';
import WaveBackground from './WaveBackground';
import ScrollToTop from './ScrollToTop';
import DoctorsGrid from './DoctorsGrid';
import FounderGrid from './FounderGrid';
import FAQPage from './FAQPage';
import BackProgram from './BackProgram';
import PervanMethodSection from './PervanMethodSection';

function App() {
  const [isSticky, setIsSticky] = useState(true);

  // Function to handle the screen size change
  const handleResize = () => {
    if (window.innerWidth < 992) { // Example breakpoint for mobile
      setIsSticky(false);
    } else {
      setIsSticky(true);
    }
  };

  const scrollToCTA = (e) => {
    const hash = window.location.hash;
    if (hash) {
      e.preventDefault();
      // Remove the leading '#' and find the element
      const element = document.getElementById(hash.substring(1));
      if (element) {
        // Use a slight delay if needed to ensure layout is settled
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 300); // adjust delay as needed
      }

      // Update the hash in the URL without reloading the page
      window.history.pushState(null, '', `${hash}`);
    }
  };

  useEffect(() => {
    // Set initial sticky value based on current screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    // Listen for the load event to ensure everything is rendered
    window.addEventListener('load', scrollToCTA);

    // Clean up the event listener on component unmount
    return () =>{
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', scrollToCTA);
    } 
  }, []);

  return (
    <Router>
      <div className="App">
        <main>
          <ScrollToTop />
          <Routes>
            {/* Route for the Landing Page */}
            <Route
              path="/"
              element={
                <>
                  <Header sticky={false} />
                  <Hero />
                  <BespokeSection />
                  { WaveBackground("#ffffff", "wave-background-gray") }
                  <PopularDestinations />
                  { WaveBackground("#f8f9fa", "wave-background") }
                  <PervanMethodSection/>
                  { WaveBackground("#ffffff", "wave-background-gray") }
                  <MissionSection />
                  { WaveBackground("#f8f9fa", "wave-background") }
                  <DoctorGallery />
                  <CTA />
                </>
              }
            />

            <Route 
              path="/ourdoctors" 
              element={ 
                <>
                  <Header sticky={isSticky} />
                  <DoctorsGrid /> 
                </>
              } 
            />

            <Route 
              path="/ourteam" 
              element={ 
                <>
                  <Header sticky={isSticky} />
                  <FounderGrid /> 
                </>
              } 
            />

            <Route 
              path="/faq" 
              element={ 
                <>
                  <Header sticky={isSticky} />
                  <FAQPage /> 
                </>
              } 
            />

            <Route
              path="/backprogram"
              element={
                <>
                  <Header sticky={isSticky} />
                  <BackProgram />
                </>
              }
            />
          </Routes>
        </main>
        <Footer />
        <a href="#top" className="go-top" data-go-top>
          <ion-icon name="chevron-up-outline"></ion-icon>
        </a>
      </div>
    </Router>
  );
}

export default App;
